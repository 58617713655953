import * as React from "react"
// import { graphql } from "gatsby"
import { css } from "@emotion/react"
import { Layout } from "../components/layout"
// import GalleryPageVideo from "../components/galleryPageVideo"
import GalleryPageImages from "../components/galleryPageImages"
import ShopSection from "../components/shopSection"
import ContactUsSection from "../components/contactUsSection"
import { device } from "../styles/device"

const GalleryPage = () => {
    return (
        <Layout>
            <div css={videoContainer}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/7FpnNN_5jd0?modestbranding=1&rel=0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <GalleryPageImages />
            <ContactUsSection />
            <ShopSection />
        </Layout>
    )
}
export default GalleryPage

const videoContainer = css`
    width: 72vw;
    height: 40.5vw;
    margin: 0 auto 20vh;
    padding-top: 3vh;
    display: block;

    @media ${device.tb} {
        width: 80vw;
        height: 45vw;
        padding-top: 7vh;
    }
    @media ${device.sp} {
        width: 100%;
        height: 56.25vw;
        padding: 7vh 0 13vh;
        margin: 0 auto;
    }
`
