import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { device } from "../styles/device"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const AnimateImage = ({ photo }) => {
    const controls = useAnimation()
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        inView ? controls.start("animate") : controls.start("initial")
    }, [controls, inView])

    const transition = { duration: 1.8, ease: [0.6, -0.05, 0.01, 0.9] }
    const animateGalleryPhoto = {
        initial: {
            opacity: 0,
            y: "40%",
            scaleY: "106%",
        },
        animate: {
            opacity: 1,
            y: 0,
            scaleY: "100%",
        },
    }

    return (
        <motion.div
            ref={ref}
            initial="initial"
            animate={controls}
            transition={transition}
            variants={animateGalleryPhoto}
            key={photo}
            style={{
                display: "inline-block",
                position: "relative",
                verticalAlign: "top",
            }}
        >
            <GatsbyImage image={getImage(photo)} alt="photo" css={galleryImage} key={`gallery-image-`} />
        </motion.div>
    )
}

const GalleryPageImages = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulPhotoGallery(filter: { node_locale: { eq: "ja" } }) {
                nodes {
                    galleryImages {
                        gatsbyImageData
                    }
                }
            }
        }
    `)
    const content = data.allContentfulPhotoGallery.nodes[0] //no English translation

    return (
        <div css={wrapper}>
            {content.galleryImages.map((photo) => (
                <AnimateImage photo={photo} key={photo.gatsbyImageData.images.fallback.src} />
            ))}
        </div>
    )
}
export default GalleryPageImages

const wrapper = css`
    margin-bottom: 30vmax;
    div:nth-of-type(5n + 1) {
        width: 52%;
        height: 52vh;
        margin: 15vh 7% 0 3%;
    }
    div:nth-of-type(5n + 2) {
        width: 33%;
        height: 40vh;
        margin: 35vh 0 0 0;
    }
    div:nth-of-type(5n + 3) {
        width: 40%;
        height: 44vh;
        margin: 50vh 23% 0 9%;
    }
    div:nth-of-type(5n + 4) {
        width: 28%;
        height: 84vh;
        margin: 20vh 0 0 0;
    }
    div:nth-of-type(5n + 5) {
        width: 40%;
        height: 40vh;
        margin: 20vh 60% 0 0;
    }

    @media ${device.tb} {
        div:nth-of-type(5n + 1) {
            width: 52%;
            height: 52vmin;
            margin: 15vmin 7% 0 3%;
        }
        div:nth-of-type(5n + 2) {
            width: 33%;
            height: 40vmin;
            margin: 35vmin 0 0 0;
        }
        div:nth-of-type(5n + 3) {
            width: 40%;
            height: 44vmin;
            margin: 50vmin 23% 0 9%;
        }
        div:nth-of-type(5n + 4) {
            width: 28%;
            height: 84vmin;
            margin: 20vmin 0 0 0;
        }
        div:nth-of-type(5n + 5) {
            width: 40%;
            height: 40vmin;
            margin: 20vmin 60% 0 0;
        }
    }

    @media ${device.sp} {
        div:nth-of-type(5n + 1) {
            width: 78%;
            height: 25vmax;
            margin: 18vmax 0 0 3%;
        }
        div:nth-of-type(5n + 2) {
            width: 60%;
            height: 22vmax;
            margin: 5vmax 0 0 35%;
        }
        div:nth-of-type(5n + 3) {
            width: 50%;
            height: 25vmax;
            margin: 10vmax 0 0 9%;
        }
        div:nth-of-type(5n + 4) {
            width: 75%;
            height: 62vmax;
            margin: 20vmax 0 0 25%;
        }
        div:nth-of-type(5n + 5) {
            width: 75%;
            height: 25vmax;
            margin: 6vmax 0 0 0;
        }
    }
`
const galleryImage = css`
    display: inline-block;
    position: relative;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
`
